import React from 'react';

class NotFound extends React.Component {

    render() {
        return(
            <div>
                Page Not Found.
            </div>
        )
    }
}

export default NotFound