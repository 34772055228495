import React from 'react'

const content = require('../../content/content')

const jollylubeImg = require('../../images/jollylube.png')

class Bookmarks extends React.Component {
    render() {
        return(
            <section className="bookmarks">
                <h2 className="project-title">{ content.projects.jollylube.title }</h2>
                <p className="project-links">
                    <a href={ content.projects.jollylube.live } target='blank'>Live</a>
                    <a href={ content.projects.jollylube.repo } target='blank'>Repo</a>
                </p>
                <img src={ jollylubeImg } alt="Bookmarks App" />
                <p className="tech">
                    <span className="tech-item" >HTML5</span>
                    <span className="tech-item" >CSS3</span>
                    <span className="tech-item" >JavaScript</span>
                    <span className="tech-item" >React.js</span>
                    <span className="tech-item" >JSX</span>
                    <span className="tech-item" >Node.js</span>
                    <span className="tech-item" >Express</span>
                    <span className="tech-item" >Postgres</span>
                    <span className="tech-item" >Heroku</span>
                    <span className="tech-item" >Zeit/Now.sh</span>
                    <span className="tech-item" >Json Web Tokens</span>
                    <span className="tech-item" >Unit Testing</span>
                </p>
                <p className="project-description" >{ content.projects.jollylube.desc }</p>
            </section>
        )
    }
}

export default Bookmarks
