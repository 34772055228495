import React from 'react'

const content = require('../../content/content')

const bookmarksImg = require('../../images/bookmarks.png')

class Bookmarks extends React.Component {
    render() {
        return(
            <section className="bookmarks">
                <h2 className="project-title">{ content.projects.bookmarks.title }</h2>
                <p className="project-links">
                    <a href={ content.projects.bookmarks.live } target='blank'>Live</a>
                    <a href={ content.projects.bookmarks.repo } target='blank'>Repo</a>
                </p>
                <img src={ bookmarksImg } alt="Bookmarks App" />
                <p className="tech">
                    <span className="tech-item" >HTML5</span>
                    <span className="tech-item" >CSS3</span>
                    <span className="tech-item" >JavaScript</span>
                    <span className="tech-item" >jQuery</span>
                </p>
                <p className="project-description" >{ content.projects.bookmarks.desc }</p>
            </section>
        )
    }
}

export default Bookmarks
