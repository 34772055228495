import React from 'react';

//import project Components
import Bookmarks from '../../components/Bookmarks/Bookmarks';
import Quiz from '../../components/Quiz/Quiz';
import JollyLube from '../../components/JollyLube/JollyLube';

import './projects.css';

class Projects extends React.Component {

    render() {
        return(
            <div className='projects'>
                <JollyLube />
                <Quiz />
                <Bookmarks />
            </div>
        )
    }
}

export default Projects