import React from 'react';

import './aboutMe.css';

const content = require('../../content/content');

class AboutMe extends React.Component {

    render() {
        return(
            <section className='aboutme'>
                <p>
                    { content.textContent.home.p1 }
                </p>
                <p>
                    { content.textContent.home.p2 }
                </p>
                <p>
                    { content.textContent.home.p3 }
                </p>
                <p>
                    { content.textContent.home.p4 }
                </p>
                <p>
                    { content.textContent.home.p5 }
                </p>
                <p>
                    { content.textContent.home.p6 }
                </p>
                <p>
                    { content.textContent.home.p7 }
                </p>
            </section>
        )
    }
}

export default AboutMe